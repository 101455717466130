// These are the expected types for the API responses.  This is currently manually maintained, but in the future
// we could auto-generate these from the go source code with something like: https://github.com/gzuidhof/tygo.

// From /go/pkg/datacollect/data/userlogin.go
export type UserLogin = {
  email: string;
  password: string;
};

export type UserLoginResponse = {
  token: string;
};

// From /go/pkg/datacollect/data/accounts.go
export type UserAccount = {
  account_id: string;
  clinic_id: string;
  clinic_name: string;
};

// From /go/pkg/datacollect/data/petinfo.go
export type PetOwnerInfo = {
  user_id: string;
  first_name: string;
  last_name: string;
};

export type PetInfo = {
  id: string;
  name: string;
  clinic_pet_id: string;
  owner: PetOwnerInfo[];
};

export type PetNameChange = {
  pet_id: string;
  new_name: string;
};

export type PetAndClinicInfo = {
  id: string;
  name: string;
  clinic_pet_id: string;
};

export type PetCheckupStats = {
  num_checkups: number;
  num_items_uploaded: number;
  num_surveys_answered: number;
};

// From /go/pkg/datacollect/data/requests.go
export type VideoType = 'default';
export type UploadItemType = 'video';
export type CheckupRequestStatus = 'not_started' | 'not_complete' | 'complete';
export type CheckupRequestAnalysisStatus = 'not_started' | 'processing' | 'gait_anomaly' | 'no_anomaly_detected';

export type VideoUploadRequest = {
  video_type: VideoType;
};

export type CheckupRequest = {
  clinic_pet_id: string;
  requests: VideoUploadRequest[];
};

export type UploadRequestItem = {
  item: string;
  item_id: string;
  item_type: UploadItemType;
  upload_count: number;
};

export type CheckUpRequestResponse = {
  id: string;
  url: string;
  unknown_pet_id: boolean;
  pet_id: string;
};

export type CheckupRequestForPet = {
  pet_id: string;
  requests: VideoUploadRequest[];
  answers_id?: string;
};

export type CreateCheckupRequestForPetResult = {
  checkup_id: string;
};

export type AnsweredDetails = {
  survey_id: string;
  answers_id: string;
};

export type CheckupRequestStatusResponse = {
  id: string;
  pet_id: string;
  clinic_pet_id: string;
  pet_name: string;
  vet_first_name: string;
  vet_last_name: string;
  status: CheckupRequestStatus;
  analysis_status: CheckupRequestAnalysisStatus;
  request_created: string;
  request_completed?: string;
  answered_surveys?: AnsweredDetails[];
};

export type CheckupRequestInfo = {
  id: string;
  pet_id: string;
  pet_name: string;
  clinic_id: string;
  clinic_pet_id: string;
  vet_first_name: string;
  vet_last_name: string;
  request_created: string;
  num_asset_ids: number;
  num_direct_uploads: number;
  num_items_requested: number;
  requests: UploadRequestItem[];
  answered_surveys?: AnsweredDetails[];
};

export type CheckupsForPetWithSurveyAnswer = {
  id: string;
  request_created: string;
  clinic_id: string;
  pet_id: string;
  pet_name: string;
  requested_user_id: string;
  survey_id: string;
};

export type RequestSingleItemUploadStatus = {
  item_id: string;
  direct_upload: string;
  asset_id: string;
};

export type ItemUploadSignedUrl = {
  url: string;
  valid_until: string;
  item_id: string;
  video_already_uploaded: boolean;
};

export type ItemPlaybackUrl = {
  url: string;
  valid_until: string;
  item_id: string;
};

export type ChangePasswordRequest = {
  old_password: string;
  new_password: string;
};

// Auth Payload
export const AuthRoles = { global_admin: 'global_admin' } as const;
export type AuthRoleType = (typeof AuthRoles)[keyof typeof AuthRoles];
export type JwtPayload = {
  user_id: string;
  account_id: string;
  clinic_id: string;
  roles: AuthRoleType[];
  password_needs_reset: boolean;
};

export type ApiErrorResponse = {
  error: string;
};

export type BaseCheckupUrl = {
  base_url: string;
};

// From /go/pkg/datacollect/data/surveys.go
export type SurveyInfo = {
  survey_id: string;
  version: number;
  name: string;
  title: string;
  description: string;
  created_at: string;
};

export type SurveyFull<T> = SurveyInfo & {
  questions: T[];
};

export type SurveyAnswersRequest = {
  survey_id: string;
  version: number;
  checkup_request_ids: string[];
  answers: Record<string, unknown>;
};

export type SurveyAnswersUpdateRequest = {
  survey_id: string;
  version: number;
  answers_id: string;
  answers: Record<string, unknown>;
  checkups_to_add?: string[];
};

export type SurveyAnswersInfo = {
  answers_id: string;
  survey_id: string;
  version: number;
  survey_name: string;
  survey_title: string;
  survey_description: string;
  clinic_id: string;
};

export type SurveyAnswersFull = SurveyAnswersInfo & {
  answers: Record<string, unknown>;
  checkup_request_ids: string[];
};

// From /go/pkg/datacollect/data/itemreviews.go
export type ItemToReview = {
  item_id: string;
  item_type: UploadItemType;
  request_id: string;
  requested_at: string;
  clinic_id: string;
  clinic_name: string;
  pet_id: string;
  pet_name: string;
  first_name: string;
  last_name: string;
  num_reviews: number;
};

export type ItemReviewAnswerRequest = {
  item_id: string;
  survey_id: string;
  version: number;
  answers: Record<string, unknown>;
};

// From /go/pkg/datacollect/data/reviews.go

export type AnsweredCheckupsWithBonusVideos = {
  pet_id: string;
  latest_answered_request_id: string;
  requested_at: string;
  answers_id: string;
  clinic_pet_id: string;
  clinic_id: string;
  clinic_name: string;
  pet_name: string;
  num_unanswered_checkups: number;
};
// From /go/pkg/datacollect/data/clinics.go

export type ClinicInfo = {
  clinic_id: string;
  name: string;
  slug: string;
};

export type ClinicPet = {
  clinic_pet_id: string;
  pet_name: string;
};

export type ClinicWithPetsResponse = {
  clinic_id: string;
  clinic_name: string;
  slug: string;
  pets?: ClinicPet[];
};
